var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"formRef",staticClass:"com-form",attrs:{"label-width":_vm.showLabel ? '100px' : '0',"model":_vm.formModel,"rules":_vm.formRules}},[_c('p',[_vm._v("网站地址后缀：")]),_vm._l((_vm.paramsList),function(item,index){return _c('el-form-item',{key:index,attrs:{"label":index == 0 && _vm.showLabel ? '网站地址后缀' : ''}},[_c('el-form-item',{attrs:{"prop":"key","rules":{
            validator: (rule, value, callback) =>
                _vm.handleValidateEvent(rule, item.key, callback, {
                    prop: 'key',
                }),
            trigger: ['change', 'blur']
        }}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入变量名"},on:{"input":(val) => _vm.handleChangeEvent(item.key, { prop: 'key', index })},model:{value:(item.key),callback:function ($$v) {_vm.$set(item, "key", $$v)},expression:"item.key"}})],1),_c('span',{staticClass:"px-10"},[_vm._v("=")]),_c('el-form-item',{attrs:{"prop":"value","rules":{
            validator: (rule, value, callback) =>
                _vm.handleValidateEvent(rule, item.value, callback, {
                    prop: 'value',
                }),
            trigger: ['change', 'blur']
        }}},[_c('el-select',{attrs:{"disabled":!item.key,"allow-create":"","clearable":"","filterable":"","placeholder":"请选择命名变量或直接输入"},on:{"change":(val) => _vm.handleChangeEvent(item.value, { prop: 'value', index })},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},_vm._l((_vm.selDatas),function(opt,i){return _c('el-option',{key:i,attrs:{"label":opt,"value":opt}})}),1)],1),_c('el-tooltip',{attrs:{"content":"删除"}},[(_vm.paramsList.length > 0)?_c('i',{staticClass:"el-icon-remove-outline color-theme cursor-pointer ml-10",on:{"click":function($event){return _vm.handleDelItem(index)}}}):_vm._e()])],1)}),_c('el-form-item',[(_vm.paramsList.length < 5)?_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.handleAddItem}},[_vm._v("添加")]):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }