<template>
    <el-form class="com-form" :label-width="showLabel ? '100px' : '0'" ref="formRef" :model="formModel" :rules="formRules">
        <p>网站地址后缀：</p>
        <el-form-item v-for="(item, index) in paramsList" :key="index" :label="index == 0 && showLabel ? '网站地址后缀' : ''">
            <el-form-item prop="key" :rules="{
                validator: (rule, value, callback) =>
                    handleValidateEvent(rule, item.key, callback, {
                        prop: 'key',
                    }),
                trigger: ['change', 'blur']
            }">
                <el-input @input="(val) => handleChangeEvent(item.key, { prop: 'key', index })" clearable placeholder="请输入变量名"
                    v-model="item.key"></el-input>
            </el-form-item>
            <span class="px-10">=</span>
            <el-form-item prop="value" :rules="{
                validator: (rule, value, callback) =>
                    handleValidateEvent(rule, item.value, callback, {
                        prop: 'value',
                    }),
                trigger: ['change', 'blur']
            }">
                <el-select :disabled="!item.key" @change="(val) => handleChangeEvent(item.value, { prop: 'value', index })"
                    allow-create clearable filterable v-model="item.value" placeholder="请选择命名变量或直接输入">
                    <el-option v-for="(opt, i) in selDatas" :key="i" :label="opt" :value="opt">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- 删除 -->
            <el-tooltip content="删除">
                <i v-if="paramsList.length > 0" @click="handleDelItem(index)"
                    class="el-icon-remove-outline color-theme cursor-pointer ml-10"></i>
            </el-tooltip>
        </el-form-item>
        <!-- 添加 -->
        <el-form-item>
            <el-button v-if="paramsList.length < 5" type="text" @click="handleAddItem">添加</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
export default {
    props: {
        selDatas: {
            type: Array,
            default: () => []
        },
        initData: {
            type: Object,
            default: () => { }
        },
        currentTag: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        // 获取已有参数
        definedKey() {
            let array = [];
            this.initData.tplStr.split('&').forEach(item => array.push(item.split('=')[0]));
            // this.initData.cusStr.split('&').forEach(item => array.push(item.split('=')[0]));
            this.currentTag?(this.currentTag.split('&').forEach(item => array.push(item.split('=')[0]))):null
            return array
        },
        createCusStrFn() {
            let list = []
            this.uniqueArr(this.paramsList).forEach((item, index) => {
                list.push(`${item.key}=${item.value}`)
            });
            return list.join('&')
        },
        // paramsList: {
        //     get() {
        //         let list = [];
        //         if (this.currentTag.length > 0) {
        //             this.currentTag.split('&').forEach((item, index) => {
        //                 list.push({
        //                     key: item.split('=')[0] || '',
        //                     value: item.split('=')[1] || ''
        //                 })
        //             });

        //         }
        //         return list
        //     },
        //     set() {
        //         this.$emit('customSetEvent', this.createCusStrFn)
        //     }
        // }

    },
    data() {
        return {
            formModel: {},
            formRules: {},
            reg:/[~!@#\$%\^&*()+\-=[\]\|\\:;"'<,>?\/\s]/,
            paramsList:[]
        }
    },
    methods: {
        uniqueArr(arr) {
            return arr.filter((item, index, self) => {
                return index === self.findIndex(obj => obj.key === item.key && obj.value === item.value);
            })
        },
        handleAddItem() {
            this.paramsList.push({ key: '', value: '' });
            // this.$emit('customSetEvent', this.createCusStrFn)
        },
        handleDelItem(index) {
            this.paramsList.splice(index, 1);
            // this.$emit('customSetEvent', this.createCusStrFn)
        },
        handleValidateEvent(rule, value, callback, { prop, mark }) {
            let tip = "";
            // select改变
            if (prop == "value") {
                tip = "请选择命名变量或直接输入";
            } else {
                // input改变
                tip = "请输入变量名";
            }
            if (!value) {
                callback(new Error(tip));
            } else {
                if (prop == "key") {
                    if (this.definedKey.filter(v => v == value).length > 1) {
                        callback(new Error('该参数已经存在，请修改参数'));
                    }
                }
                if(this.reg.test(value)){
                    callback(new Error('请不要输入特殊字符')); 
                }
                if(value.length > 20) {
                    callback(new Error('长度不超过20')); 
                }
                callback();
            }
        },
        // 输入框-选择框-改变
        handleChangeEvent(val, { prop, index }) {
            let item = this.paramsList[index];
            item[prop] = val;
            this.$set(this.paramsList, index, item)
            // this.$emit('customSetEvent', this.createCusStrFn)
            // this.createCusStrFn();
        },
        // create-cusStr

    },
    watch:{
        currentTag:{
            handler(){
                let list = [];
                if (this.currentTag.length > 0) {
                    this.currentTag.split('&').forEach((item, index) => {
                        list.push({
                            key: item.split('=')[0] || '',
                            value: item.split('=')[1] || ''
                        })
                    });

                }
                this.paramsList = list
            },
            deep:true,
            immediate:true
        }
    },
}
</script>
<style lang="scss">
@import "@/styles/commonly";

.com-form {
    .el-input {
        width: auto;
    }

    .el-form-item__content {
        @extend .dp-flex;
        @extend .align-item-c;
    }
}
</style>